import { createSlice } from "@reduxjs/toolkit";
import { getLotTransferInByID, getLotTransferInList, LotTransferInAdd, LotTransferInEdit,LotTransferInDelete } from './lotTransferInAction';

const LotTransferInSlice = createSlice({
    name: "lotTransferin",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        lotTransferInlist: [],
        lottransferInitems: [],
        lotTransferInParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
    },
    reducers: {
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetLotTransferInParams: (state, action) => {
            return {
                ...state,
                lotTransferInParams: {
                    ...state.lotTransferInParams,
                    ...action.payload
                },
            };
        },
        SetLotTransferInItemParams: (state, action) => {
            return {
                ...state,
                pageParams: {
                    ...state.pageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setLotInItemsList: (state, action) => {
            return {
                ...state,
                lottransferInitems: [...state.lottransferInitems, action.payload]
            }
        },
        // changeParams: (state, action) => {
        // 	return {
        // 		...state,
        // 		pageParams: action.payload,
        // 	};
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLotTransferInList.pending, (state, action) => {
                state.status = " loading";
                state.loading = true;
            })
            .addCase(getLotTransferInList.fulfilled, (state, action) => {
                state.status = "getLotTransferInList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.vehicleInwardParams?.page_size
                );

                state.lotTransferInlist = response.results;
                state.listCount = response.count;
                state.lotTransferInParams = {
                    ...state.lotTransferInParams,
                    no_of_pages: noofpages,
                };   
                state.lottransferInitems = [];             
            })
            .addCase(getLotTransferInList.rejected, (state, action) => {
                state.status = "getLotTransferInList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getLotTransferInByID.pending, (state, action) => {
                state.status = "getLotTransferInByID loading";
                state.loading = true;
            })
            .addCase(getLotTransferInByID.fulfilled, (state, action) => {
                state.status = "getLotTransferInByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.listItemCount = action.payload.lottransferinitems?.length;
            }
            )
            .addCase(getLotTransferInByID.rejected, (state, action) => {
                state.status = "getLotTransferInByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(LotTransferInAdd.pending, (state, action) => {
                state.status = "LotTransferInAdd loading";
                state.loading = true;
            })
            .addCase(LotTransferInAdd.fulfilled, (state, action) => {
                state.status = "LotTransferInAdd succeeded";
                state.loading = false;
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.lottransferInitems = [];
            }
            )
            .addCase(LotTransferInAdd.rejected, (state, action) => {
                state.status = "LotTransferInAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(LotTransferInEdit.pending, (state, action) => {
                state.status = "LotTransferInEdit loading";
                state.loading = true;
            })
            .addCase(LotTransferInEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "LotTransferInEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
            })
            .addCase(LotTransferInEdit.rejected, (state, action) => {
                state.status = "LotTransferInEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(LotTransferInDelete.pending, (state, action) => {
                state.status = "LotTransferInDelete loading";
                state.loading = true;
            })
            .addCase(LotTransferInDelete.fulfilled, (state, action) => {
                state.status = "LotTransferInDelete succeeded";
                state.loading = false
            }
            )
            .addCase(LotTransferInDelete.rejected, (state, action) => {
                state.status = "LotTransferInDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetLotTransferInParams,
    isModelVisible,
    isDrawerVisible,
    setLotInItemsList,
    SetLotTransferInItemParams
} = LotTransferInSlice.actions;

export default LotTransferInSlice.reducer;