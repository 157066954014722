import { createSlice } from "@reduxjs/toolkit";
import { getLotTransferOutByID, getLotTransferOutList, LotTransferOutAdd, LotTransferOutDelete } from "./lotTransferOutAction";

const lotTransferOutSlice = createSlice({
    name: "lotTransferout",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        listCount: 0,
        lotTransferOutlist: [],
        lottransferoutitems:[],
        lotTransferOutParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        }

    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetLotTransferOutParams: (state, action) => {
            return {
                ...state,
                lotTransferOutParams: {
                    ...state.lotTransferOutParams,
                    ...action.payload
                },
            };
        },
        SetLotTransferOutItemParams: (state, action) => {
            return {
                ...state,
                pageParams: {
                    ...state.pageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setLotItemsList: (state, action) => {
            return {
                ...state,
                lottransferoutitems: [...state.lottransferoutitems, action.payload]
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLotTransferOutList.pending, (state, action) => {
                state.status = "getLotTransferOutList loading";
                state.loading = true;
            })
            .addCase(getLotTransferOutList.fulfilled, (state, action) => {
                state.status = "getLotTransferOutList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.lotTransferOutParams?.page_size
                );

                state.lotTransferOutlist = response.results;
                state.listCount = response.count;
                state.lotTransferOutParams = {
                    ...state.lotTransferOutParams,
                    no_of_pages: noofpages,
                };
                state.lottransferoutitems = [];
            })
            .addCase(getLotTransferOutList.rejected, (state, action) => {
                state.status = "getLotTransferOutList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getLotTransferOutByID.pending, (state, action) => {
                state.status = "getLotTransferOutByID loading";
                state.loading = true;
            })
            .addCase(getLotTransferOutByID.fulfilled, (state, action) => {
                state.status = "getLotTransferOutByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.listItemCount = action.payload.lottransferoutitems.length;
            }
            )
            .addCase(getLotTransferOutByID.rejected, (state, action) => {
                state.status = "getLotTransferOutByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(LotTransferOutAdd.pending, (state, action) => {
                state.status = "LotTransferOutAdd loading";
                state.loading = true;
            })
            .addCase(LotTransferOutAdd.fulfilled, (state, action) => {
                state.status = "LotTransferOutAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.lottransferoutitems = [];
            }
            )
            .addCase(LotTransferOutAdd.rejected, (state, action) => {
                state.status = "LotTransferOutAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(LotTransferOutDelete.pending, (state, action) => {
                state.status = "LotTransferOutDelete loading";
                state.loading = true;
            })
            .addCase(LotTransferOutDelete.fulfilled, (state, action) => {
                state.status = "LotTransferOutDelete succeeded";
                state.loading = false
            }
            )
            .addCase(LotTransferOutDelete.rejected, (state, action) => {
                state.status = "LotTransferOutDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetLotTransferOutParams,
    isModelVisible,
    isDrawerVisible,
    setLotItemsList,
    SetLotTransferOutItemParams
} = lotTransferOutSlice.actions;

export default lotTransferOutSlice.reducer;